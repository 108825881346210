/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {useState} from "react";

// react-router-dom components
import {Link} from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

// Icons
import {FaApple, FaFacebook, FaGoogle} from "react-icons/fa";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
// import bgSignIn from "assets/images/signUpImage.png";
import bgSignIn from "assets/images/image_adobe_express.jpg";
import VuiSelect from "../../../components/VuiSelect";
import {BACKEND_SIGN_UP_URL} from "../../../CONSTANTS";
import VuiSnackbar from "../../../components/VuiSnackbar";

const REGIONS_WE_CHECK = [
    {value: "toronto", label: "Toronto"},
    {value: "ottawa", label: "Ottawa"},
    {value: "mississauga", label: "Mississauga"},
    {value: "brampton", label: "Brampton"},
    {value: "hamilton", label: "Hamilton"},
    {value: 'london', label: "London"},
    {value: 'markham', label: "Markham"},
    {value: 'vaughan', label: "Vaughan"},
    {value: 'kitchener', label: "Kitchener"},
    {value: 'windsor', label: "Windsor"},
    {value: 'richmond hill', label: "Richmond Hill"},
    {value: 'burlington', label: "Burlington"},
    {value: 'sudbury', label: "Sudbury"},
    {value: 'oshawa', label: "Oshawa"},
    {value: 'barrie', label: "Barrie"}
]

function SignIn() {
    const [rememberMe, setRememberMe] = useState(true);
    const [email, setEmail] = useState('')
    const [region, setRegion] = useState('')
    const [showSnackbar, setShowSnackbar] = useState(false);
    const toggleSnackbar = () => setShowSnackbar(!showSnackbar);

    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    const handleSetRegion = (evt) => {
        console.log(evt)
        let regionReceived = evt.value
        console.log("received region - " + regionReceived)
        setRegion(regionReceived)
        // console.log("region is now: " + region)
    }

    const handleSetEmail = (evt) => {

        console.log(evt)
        let emailReceived = evt.target.value
        console.log("received email - " + emailReceived)
        setEmail(emailReceived)
    }

    const handleSignUp = () => {
        const requestOptions = {
            method: 'GET',
        };
        let date = new Date().toJSON();

        const fullBackendURL = BACKEND_SIGN_UP_URL + "?email=" + encodeURIComponent(email) + "&region=" + encodeURIComponent(region)
            + "&time=" + encodeURIComponent(date)
        console.log("Sent Message To Backend - " + fullBackendURL)
        fetch(fullBackendURL, requestOptions).then(response => response.json())

        toggleSnackbar()
        setTimeout(() => {
            setShowSnackbar(false)
        }, "7000")
    }

    return (
        <CoverLayout
            title="Looking For A Family Doctor In Ontario?"
            color="lightblue"
            description="So are we! Sign up below for a waitlist and we'll email you as soon as we find one in your area."
            image={bgSignIn}
            premotto="Our mission is to"
            motto="GET YOU A FAMILY DOCTOR"
            cardContent
        >
            <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
                <VuiBox
                    component="form"
                    role="form"
                    borderRadius="inherit"
                    p="45px"
                    sx={({palette: {secondary}}) => ({
                        backgroundColor: secondary.focus,
                    })}
                >
                    <VuiTypography
                        color="white"
                        fontWeight="bold"
                        textAlign="center"
                        mb="24px"
                        sx={({typography: {size}}) => ({
                            fontSize: size.lg,
                        })}
                    >
                        Register Below To Be Notified
                    </VuiTypography>

                    <VuiBox mb={2}>
                        <VuiBox mb={1} ml={0.5}>
                            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                                Email
                            </VuiTypography>
                        </VuiBox>
                        <GradientBorder
                            minWidth="100%"
                            borderRadius={borders.borderRadius.lg}
                            padding="1px"
                            backgroundImage={radialGradient(
                                palette.gradients.borderLight.main,
                                palette.gradients.borderLight.state,
                                palette.gradients.borderLight.angle
                            )}
                        >
                            <VuiInput
                                name="email"
                                type="email"
                                placeholder="Your email..."
                                sx={({typography: {size}}) => ({
                                    fontSize: size.sm,
                                })}
                                autoComplete="email"
                                value={email}
                                onChange={handleSetEmail}

                            />
                        </GradientBorder>
                    </VuiBox>
                    <VuiBox mb={2}>
                        <VuiBox mb={1} ml={0.5}>
                            <VuiTypography component="label" variant="button" color="white" fontWeight="medium"
                            >
                                Region
                            </VuiTypography>
                        </VuiBox>
                        <GradientBorder
                            minWidth="100%"
                            borderRadius={borders.borderRadius.lg}
                            padding="1px"
                            backgroundImage={radialGradient(
                                palette.gradients.borderLight.main,
                                palette.gradients.borderLight.state,
                                palette.gradients.borderLight.angle
                            )}
                        >
                            <VuiSelect
                                placeholder="Your area"
                                sx={({typography: {size}}) => ({
                                    fontSize: size.sm,
                                })}
                                options={REGIONS_WE_CHECK}
                                onChange={handleSetRegion}
                            />
                        </GradientBorder>
                    </VuiBox>
                    {/*<VuiBox display="flex" alignItems="center">*/}
                    {/*    <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe}/>*/}
                    {/*    <VuiTypography*/}
                    {/*        variant="caption"*/}
                    {/*        color="white"*/}
                    {/*        fontWeight="medium"*/}
                    {/*        onClick={handleSetRememberMe}*/}
                    {/*        sx={{cursor: "pointer", userSelect: "none"}}*/}
                    {/*    >*/}
                    {/*        &nbsp;&nbsp;&nbsp;&nbsp;Remember me*/}
                    {/*    </VuiTypography>*/}
                    {/*</VuiBox>*/}
                    <VuiBox mt={4} mb={1}>
                        <VuiButton color="info" fullWidth onClick={handleSignUp}>
                            SIGN UP
                        </VuiButton>
                    </VuiBox>
                    <VuiBox mt={3} textAlign="center">
                        <VuiTypography variant="button" color="text" fontWeight="regular">
                            Due to the high volume of requests, we will only reach out to you if we have found an
                            available doctor in your area.
                            {/*<VuiTypography*/}
                            {/*    component={Link}*/}
                            {/*    to="/authentication/sign-in"*/}
                            {/*    variant="button"*/}
                            {/*    color="white"*/}
                            {/*    fontWeight="medium"*/}
                            {/*>*/}
                            {/*    Sign in*/}
                            {/*</VuiTypography>*/}
                        </VuiTypography>
                    </VuiBox>
                </VuiBox>

            </GradientBorder>
            <VuiSnackbar
                color="info"
                icon="assignment"
                title="Accepting Patients"
                content={`Hi ${email} - We're working to find you a family doctor in ${region.charAt(0).toUpperCase() + region.slice(1)}`}
                dateTime="Just Now"
                open={showSnackbar}
                close={toggleSnackbar}
            />
        </CoverLayout>
    );
}

export default SignIn;
